import { useEffect, useState } from 'react'
import Question from '../icons/Question.jsx';
import { createPortal } from 'react-dom'
import Loading from '../components/Loading.jsx';
import axios from 'axios';
import AddressAlert from '../components/AddressAlert.jsx';
import useError from '../hooks/useError.jsx';
import ErrorAlert from '../components/ErrorAlert.jsx';
import * as Sentry from '@sentry/react';
import useAddressData from '../hooks/useAddressData.jsx';
import { useNavigate } from 'react-router-dom';

function Address() {
    const [alertOpen, setAlertOpen] = useState(false);
    const [imgLoading, setImgLoading] = useState(true);
    const [, setInvalidForm] = useState(false);
    const [tokenLoading, setTokenLoading] = useState(true);
    const [generalLoading, setGeneralLoading] = useState(false);
    const [addrAlertOpen, setAddrAlertOpen] = useState(false);
    const [resolvedAddress, setResolvedAddress] = useState();

    const { setErrorMessage } = useError();
    const [address] = useAddressData();
    const navigate = useNavigate();

    useEffect(() => {
        if (address) {
            const newSender = { ...sender };

            Object.keys(address).forEach(key => {

                if (Object.prototype.hasOwnProperty.call(newSender, key)) {
                    newSender[key] = { ...newSender[key], val: address[key] || "" }; // `|| ""` is used to handle null values
                    return;
                }
                if (key === "recipient") {
                    setRecipient({ ...recipient, recipContactName: { ...recipient.recipContactName, val: address[key] } })
                }
            });

            setSender(newSender);
        }
    }, [address])

    const [sender, setSender] = useState({
        customerId: {
            val: "",
            errMsg: "A valid Customer ID must be entered",
            err: false,
            req: true,
            regex: /^\d{8}$/
        },
        companyName: {
            val: "",
            errMsg: "Your company name must be between 3 and 35 characters.",
            err: false,
            req: true,
            regex: /^.{3,35}$/
        },
        fullName: {
            val: "",
            errMsg: "Your name must be at least 3 characters.",
            err: false,
            req: true,
            regex: /^.{3,}$/
        },
        address1: {
            val: "",
            errMsg: "Please enter a street address.",
            err: false,
            req: true,
            regex: /.+/
        },
        address2: {
            val: "",
            req: false,
        },
        city: {
            val: "",
            errMsg: "Please enter a city.",
            err: false,
            req: true,
            regex: /.+/
        },
        state: {
            val: "",
            errMsg: "Please select a state.",
            err: false,
            req: true,
        },
        postalCode: {
            val: "",
            errMsg: "Please enter a valid postal code.",
            err: false,
            req: true,
            regex: /^\d{5}(-\d{4})?$/
        },
        country: {
            val: 'US'
        },
        phone: {
            val: "",
            errMsg: "A valid phone number must be entered.",
            err: false,
            req: true,
            regex: /^(\d{10}|\d{3}[-']\d{3}[-']\d{4})$/
        },
        extension: {
            val: "",
            req: false,
        },
        email: {
            val: "",
            errMsg: "A valid email must be entered",
            err: false,
            req: true,
            regex: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        }
    });

    // MiraVista information
    const [recipient, setRecipient] = useState({
        recipContactName: {
            val: 'Specimen Receiving',
            errMsg: "A recipient name must be entered. The default is \"Specimen Receiving\"",
            err: false,
            req: true,
            regex: /.+/
        }
    })

    function stripObjectVals(obj) {
        let retObj = {};
        for (let key in obj) {
            retObj[key] = obj[key].val;
        }
        return retObj;
    }

    function handleInputChange(e, stateSetter) {
        const { name, value } = e.target;
        stateSetter(prevState => ({ ...prevState, [name]: { ...prevState[name], 'val': value } }));
    }

    function validateValues() {
        // Returns bool for whether an error exists
        let senderErr = false;
        let recipErr = false;

        let senderObj = { ...sender };
        let recipObj = { ...recipient };

        // Iterate over Sender object to check for invalid values
        Object.keys(senderObj).forEach((key) => {
            if (senderObj[key].req && senderObj[key].regex) {
                // Test RegEx against value (test bool returned is opposite of the bool we want to store)
                const fail = !(senderObj[key].regex.test(senderObj[key].val));
                senderObj[key].err = fail;

                if (fail) {
                    senderErr = true;
                }
            } // Handle state <select> tag differently
            else if (key == "state") {
                senderObj["state"].val === "" ? senderObj["state"].err = true : senderObj["state"].err = false;
            }
        });

        // Ensure the address does not match our street address
        if (
            (senderObj.address1.val.toUpperCase()).includes("4705 DECATUR BLVD") &&
            (senderObj.city.val.toUpperCase()) === "INDIANAPOLIS" &&
            (senderObj.state.val.toUpperCase()) === "IN" &&
            (senderObj.postalCode.val) === "46241"
        ) {
            senderObj.address1.err = true;
            senderObj.city.err = true;
            senderObj.state.err = true;
            senderObj.postalCode.err = true;
            senderErr = true;
        }

        // Check recipient val independently since there is only one value the user can edit
        if (recipObj["recipContactName"].val == "") {
            // Automatic error if val is blank
            recipObj["recipContactName"].err = true
            recipErr = true;
        }
        else if (recipObj.recipContactName.regex.test(recipObj.recipContactName.val) === false) {
            recipObj["recipContactName"].err = true;
            recipErr = true;
        }
        else {
            recipObj["recipContactName"].err = false;
            recipErr = false;
        }

        setSender(senderObj);
        setRecipient(recipObj);

        if (recipErr || senderErr) {
            setInvalidForm(true);
            return true
        }
        else {
            setInvalidForm(false);
            return false
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();

        // Validate fields
        const formError = validateValues();

        if (formError) {
            document.querySelector('#address-form').scrollIntoView({
                behavior: 'smooth'
            });

            return;
        }

        const payload = {
            "address": {
                "streetLines": [
                    sender.address1.val,
                    sender.address2.val,
                ],
                "city": sender.city.val,
                "stateOrProvinceCode": sender.state.val,
                "postalCode": sender.postalCode.val,
                "countryCode": sender.country.val
            }
        }

        setGeneralLoading(true);
        axios.post(`${import.meta.env.VITE_BASE_API_URL}/validateaddress`, payload)
            .then((res) => {
                const resAddress = res.data.token.output.resolvedAddresses[0];
                setResolvedAddress(resAddress);
                setAddrAlertOpen(true);
            })
            .catch((error) => {
                setErrorMessage(error, "Address");
            })
            .finally(() => {
                setGeneralLoading(false);
            });
    }


    return (
        <div className='flex justify-center'>
            <ErrorAlert />
            {/* Address Loading Spinner */}
            {generalLoading &&
                <div className='fixed top-0 bottom-0 left-0 right-0 w-full h-full bg-neutral-900 bg-opacity-20 flex justify-center items-center'>
                    <Loading className=' ' secondaryColor='#fff' />
                </div>}

            {/* Popup for Client ID Picture */}
            {alertOpen ? createPortal(
                <div className='fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-20'>
                    <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white border-2 border-neutral-700 rounded-lg p-10 flex flex-col justify-center items-center'>
                        {imgLoading && <Loading className=' ' secondaryColor='#fff' />}
                        <img className='mb-5' src='invoiceExample.jpg' alt='Customer ID example' onLoad={() => setImgLoading(false)} />
                        <button className='w-20 self-end' onClick={() => setAlertOpen(false)}>Close</button>
                    </div>
                </div>,
                document.body
            ) : undefined}

            {/* Address Pop-up Alert Window */}
            {resolvedAddress ? <AddressAlert open={addrAlertOpen} setOpen={setAddrAlertOpen} orig={stripObjectVals(sender)} recipient={stripObjectVals(recipient)} suggested={resolvedAddress} /> : undefined}

            <form className='flex flex-col max-w-3xl gap-y-8' id='address-form'>
                <div>
                    <h3 className='text-lg font-semibold mb-2'>The MiraVista 8-digit customer ID can be found in the top section of your last invoice.</h3>
                    <p className=''>If your practice does not have an account or you need to verify your customer ID, please contact our laboratory support team at (866) 647-2847, Option 1.</p>
                </div>
                <div className='flex flex-col gap-y-32 min-[860px]:flex-row min-[860px]:gap-32'>
                    {/* Sender Information */}
                    <div className='flex flex-col divider before:mb-4'>
                        <h2 className='font-semibold text-2xl min-[860px]:text-xl -order-1'>Sender</h2>
                        <label htmlFor='customerId' className='whitespace-nowrap inline-flex items-center'>MiraVista Customer ID
                            <Question width={16} height={16} className='rounded-full bg-orange-500 fill-white mx-1 cursor-pointer' onClick={() => setAlertOpen(true)} />
                            <span className='text-red-500'>*</span>
                        </label>
                        {sender.customerId.err ? <p className={`w-fit font-bold text-xs text-red-500 bg-white ${sender.customerId.err ? 'mb-1' : null}`}>{sender.customerId.errMsg}</p> : undefined}
                        <input type="text" name="customerId" id="customerId" placeholder='8 digit number' value={sender.customerId.val} onChange={e => handleInputChange(e, setSender)} className={`form-input ${sender.customerId.err ? null : 'mb-3'}`} />

                        <label htmlFor='companyName'>Company/Facility Name <span className='text-red-500'>*</span></label>
                        {sender.companyName.err ? <p className={`w-fit font-bold text-xs text-red-500 bg-white ${sender.companyName.err ? 'mb-1' : null}`}>{sender.companyName.errMsg}</p> : undefined}
                        <input type="text" name="companyName" id="companyName" placeholder='Facility Name' value={sender.companyName.val} onChange={e => handleInputChange(e, setSender)} className='form-input' />

                        <label htmlFor='fullName'>Full Name <span className='text-red-500'>*</span></label>
                        {sender.fullName.err ? <p className={`w-fit font-bold text-xs text-red-500 bg-white ${sender.fullName.err ? 'mb-1' : null}`}>{sender.fullName.errMsg}</p> : undefined}
                        <input type="text" name="fullName" id="fullName" placeholder='Full Name' value={sender.fullName.val} onChange={e => handleInputChange(e, setSender)} className='form-input' />

                        <label htmlFor='address1'>Address 1 <span className='text-red-500'>*</span></label>
                        {sender.address1.err ? <p className={`w-fit font-bold text-xs text-red-500 bg-white ${sender.address1.err ? 'mb-1' : null}`}>{sender.address1.errMsg}</p> : undefined}
                        <input type="text" name="address1" id="address1" placeholder='Street Address' value={sender.address1.val} onChange={e => handleInputChange(e, setSender)} className='form-input' />

                        <label htmlFor='address2'>Address 2 (optional)</label>
                        <input type="text" name="address2" id="address2" placeholder='Floor, Suite, etc' value={sender.address2.val} onChange={e => handleInputChange(e, setSender)} className='form-input' />

                        <label htmlFor='city'>City <span className='text-red-500'>*</span></label>
                        {sender.city.err ? <p className={`w-fit font-bold text-xs text-red-500 bg-white ${sender.city.err ? 'mb-1' : null}`}>{sender.city.errMsg}</p> : undefined}
                        <input type="text" name="city" id="city" placeholder='City' value={sender.city.val} onChange={e => handleInputChange(e, setSender)} className='form-input' />

                        <label htmlFor='state'>State <span className='text-red-500'>*</span></label>
                        {sender.state.err ? <p className={`w-fit font-bold text-xs text-red-500 bg-white ${sender.state.err ? 'mb-1' : null}`}>{sender.state.errMsg}</p> : undefined}
                        <select name="state" id="state" value={sender.state.val} onChange={e => handleInputChange(e, setSender)}>
                            <option value="" defaultValue>Select a State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>

                        <label htmlFor='postalCode'>Postal Code <span className='text-red-500'>*</span></label>
                        {sender.postalCode.err ? <p className={`w-fit font-bold text-xs text-red-500 bg-white ${sender.postalCode.err ? 'mb-1' : null}`}>{sender.postalCode.errMsg}</p> : undefined}
                        <input type="text" name="postalCode" id="postalCode" placeholder='Postal Code' value={sender.postalCode.val} onChange={e => handleInputChange(e, setSender)} className='form-input' />

                        <label htmlFor='country'>Country</label>
                        <input type="text" name="country" id="country" defaultValue={sender.country.val} className='disabled form-input' readOnly />

                        <label htmlFor='phone'>Phone Number <span className='text-red-500'>*</span></label>
                        {sender.phone.err ? <p className={`w-fit font-bold text-xs text-red-500 bg-white ${sender.phone.err ? 'mb-1' : null}`}>{sender.phone.errMsg}</p> : undefined}
                        <input type="text" name="phone" id="phone" placeholder='1234567890' value={sender.phone.val} onChange={e => handleInputChange(e, setSender)} className='form-input' />

                        <label htmlFor='extension'>Ext. (optional)</label>
                        <input type="text" name="extension" id="extension" placeholder='optional' value={sender.extension.val} onChange={e => handleInputChange(e, setSender)} className='form-input' />

                        <label htmlFor='email'>Email Address <span className='text-red-500'>*</span></label>
                        {sender.email.err ? <p className={`w-fit font-bold text-xs text-red-500 bg-white ${sender.email.err ? 'mb-1' : null}`}>{sender.email.errMsg}</p> : undefined}
                        <input type="email" name="email" id="email" placeholder='example@example.com' value={sender.email.val} onChange={e => handleInputChange(e, setSender)} className='form-input' />
                    </div>

                    {/* MiraVista Information */}
                    <div className='flex flex-col divider before:mb-4'>
                        <h2 className='font-semibold text-2xl min-[860px]:text-xl -order-1'>Recipient</h2>
                        <label htmlFor='recipContactName'>Contact Name <span className='text-red-500'>*</span></label>
                        {recipient.recipContactName.err ? <p className={`w-fit font-bold text-xs text-red-500 bg-white ${recipient.recipContactName.err ? 'mb-1' : null}`}>{recipient.recipContactName.errMsg}</p> : undefined}
                        <input type="text" name="recipContactName" id="recipContactName" value={recipient.recipContactName.val} onChange={e => handleInputChange(e, setRecipient)} className='form-input' />

                        <label htmlFor='recipCompanyName'>Company Name</label>
                        <input type="text" name="recipCompanyName" id="recipCompanyName" value={"MIRAVISTA DIAGNOSTICS"} className='disabled form-input' readOnly />

                        <label htmlFor='recipAddress'>Address</label>
                        <input type="text" name="recipAddress" id="recipAddress" value={"4705 DECATUR BLVD"} className='disabled form-input' readOnly />

                        <label htmlFor='recipCity'>City</label>
                        <input type="text" name="recipCity" id="recipCity" value={"INDIANAPOLIS"} className='disabled form-input' readOnly />

                        <label htmlFor='recipState'>State</label>
                        <input type="text" name="recipState" id="recipState" value={"IN"} className='disabled form-input' readOnly />

                        <label htmlFor='recipPostalCode'>Postal Code</label>
                        <input type="text" name="recipPostalCode" id="recipPostalCode" value={"46241-9603"} className='disabled form-input' readOnly />

                        <label htmlFor='recipCountry'>Country</label>
                        <input type="text" name="recipCountry" id="recipCountry" value='UNITED STATES' className='disabled form-input' readOnly />

                        <label htmlFor='recipPhone'>Phone Number</label>
                        <input type="text" name="recipPhone" id="recipPhone" value='317-856-2681' className='disabled form-input' readOnly />

                        <label htmlFor='recipEmail'>Email Address</label>
                        <input type="email" name="recipEmail" id="recipEmail" value='INFO@MIRAVISTALABS.COM' className='disabled form-input' readOnly />
                    </div>
                </div>
                <div className='flex justify-end gap-x-2'>
                    <button type='button' className={`button`} onClick={() => navigate('/')}>Back</button>
                    <button className='self-end' onClick={(e) => handleSubmit(e)}>Next</button>
                </div>
            </form>
        </div>
    )


}

const AddressWithProfiler = Sentry.withProfiler(Address);
export default AddressWithProfiler;